.changelog{
    display: flex;
    &__entries{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .legend-wrap{
        display: flex;
    }
}

.entry{
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: solid 2px var(--table-row-second);
    margin: 20px 0;
    padding: 10px 20px;
    background: #363A4B;
    border-radius: 5px;
    width: fit-content;
    .entry-line{
        display: flex;
        margin: 2px 0;
        &__title{
            color: var(--panel-text)
        }
        &__value{
            margin-left: 10px;
            color: white;
        }
    }
    &__body{
        display: flex;
        flex-direction: column;
        margin: 5px 0;
    }
}

.change{
    &__wrap{
        display: flex;
        margin: 5px 0;
    }
    &__icon{
        background: rgba(255,255,255,0.3);
        width: 15px;
        font-size: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        padding: 5px;
    }
    &__desc{
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255,255,255,0.75);
        &--multi{
            flex-direction: column;
            align-items: flex-start;
            color: #fff;
        }
    }
}


.legend{
    &__wrap{
        display: flex;
        flex-wrap: wrap;
    }
    &__item{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        color: var(--panel-text);
        border: solid 1px var(--table-row-second);
        border-radius: 5px;
        padding-right: 10px;
        .change__icon{
            width: 15px;
            height: 15px;
            font-size: 15px;
            margin-right: 10px;
            margin-bottom: 0;
        }
    }
}