@panel: #314b60;
.top-panel{
    display: flex;
    flex-direction: row;
    background: var(--top-panel-background);
    min-height: 50px;
    box-sizing: border-box;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    z-index: 15;
    .left{
        display: flex;
        justify-content: space-between;
        height: 50px;
        width: 20%;
        max-width: 20%;
        min-width: 230px;
        padding: 0 30px;
        box-sizing: border-box;
        .home{
            padding: 6px 20px 0 0;
            display: block;
            box-sizing: border-box;
            #text-logo{
                margin: 0;
            }
        }
        .view-option{
            font-size: 20px;
        }
        .theme-switch{
            display: flex;
            padding-top: 10px;
            margin-top: 10px;
            &.light .color.dark{
                z-index: 10;
            }
            &.dark .color.white{
                z-index: 10;
            }
            .color{
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                border: solid 1px black;
                &.ontop{
                    z-index: 2;
                }
                &.white{
                    background: white;
                    margin-top: -10px;
                }
                &.black{
                    background: @panel;
                    margin-left: -9px;
                }
            }
        }
        .ip-list{
            border: solid 1px var(--panel-text-shade);
            background: transparent;
            color: var(--title);
            border-radius: 20px;
            height: 30px;
            width: fit-content;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            font-weight: 200;
            margin-top: 10px;
            &--left{
                line-height: 27px;
                padding: 0;
            }
            &--right{
                line-height: 18px;
                padding-top: 5px;
            }
            .chevron{
                background: var(--link);
                display: block;
                color: var(--link);
                width: 14px;
                height: 14px;
                border-radius: 50%;
                margin: 2px 0 2px 5px;
                svg {
                    width: 8px;
                    height: 14px;
                    padding-top: 1px;
                    path{
                        fill: var(--title);
                    }
                }
                &.up{
                    transform: rotate(180deg);
                    svg {
                        padding-top: 0px;
                    }
                }
            }
            .noOutline();
            &:hover{
                color: var(--link);
                cursor: pointer;
            }
        }
        @media(max-width: 850px){
            min-width: 150px;
            padding-right: 10px;
            .ip-list{
                display: none;
            }

        }
    }
    .page-picker{
        padding-top: 10px;
        ul{
            display: flex;
            flex-direction: row;
            padding: 0;
            height: 30px;
            margin: 0;
            padding-top: 5px;
            li{
                color: var(--panel-text);
                margin-right: 20px;
                list-style: none;
                font-size: @fontSizeStandard;
                display: flex;
                .SVGInline{
                    margin-right: 5px;
                    svg{
                        path{
                            fill: var(--panel-text-shade);
                        }
                    }
                }
                &.active{
                    .SVGInline svg path {
                         fill: var(--link);
                    }
                }
                .icon-monitoring{
                    padding-top: 2px;
                }
                i{
                    margin-right: 5px;
                    margin-top: 2px;
                }
                &.active{
                    i,a{
                        color: var(--link);
                    }
                }
                &:hover{
                    a,i{color: var(--link);}
                    .SVGInline svg path {fill: var(--link);}
                }
            }
        }
    }
    .right{
        display: flex;
        flex-direction: row;
        padding-top: 10px;
        flex-grow: 1;
        &.settings-panel {
            display: flex;
            .detail-options {
                display: flex;
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    line-height: 20px;
                    height: 30px;
                    .topPanelShadow();
                    border-radius: 5px;
                    margin-left: 5px;
                    height: 35px;
                    a {
                        white-space: nowrap;
                        padding: 0 10px;
                        border-radius: 5px;
                        font-size: 15px;
                        text-transform: uppercase;
                        color: @textColor;
                        line-height: 35px;
                        height: 35px;
                        display: block;
                        .fa {
                            margin-right: 10px;
                        }
                        &:hover {
                            background: @panel;
                            cursor: pointer;
                            color: white;
                        }
                    }
                }
            }
        }
        &.detail-panel{
            flex-grow: 1;
            .back{
                border: solid 1px var(--panel-text-shade);
                background: transparent;
                color: white;
                border-radius: 20px;
                height: 30px;
                width: fit-content;
                padding: 0 15px;
                display: flex;
                justify-content: center;
                font-weight: 200;
                color: var(--panel-text);
                i{
                    background: var(--link);
                    display: block;
                    color: black;
                    height: 15px;
                    width: 15px;
                    line-height: 15px;
                    font-size: 14px;
                    border-radius: 50px;
                    .lightIcon(2px ,var(--link));
                    margin: 2px 10px;
                }
                .noOutline();
                &:hover{
                    color: var(--link);
                    cursor: pointer;
                }
            }
        }
        @media(max-width: 650px){
            .modal-wrap{display: none;}
        }
    }
    .banner{
        &-wrap{
            display: flex;
            flex: 1 0 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            &__banner{
                height: 45px;
                width: 95%;
                display: flex;
                border-radius: 3px;
                &--error{
                    background: RGBA(254, 124, 125, 1.00);
                }
                span{
                    display: flex;
                    flex-grow: 1;
                    text-align: left;
                    color: rgba(255,255,255,0.8);
                    .title{
                        color: white;
                        margin-right: 10px;
                    }
                }
                button{
                    width: 50px;
                    background: RGBA(254, 84, 86, 1.00);
                    color: rgba(255,255,255,0.8);
                    border: none;
                    border-radius: 0 3px 3px 0;
                    &:hover{
                        cursor: pointer;
                        background: RGBA(254, 18, 25, 1.00);
                        color: white;
                    }
                    &:after{
                        font-size: 40px;
                        line-height: 35px;
                        width: 35px;
                        content: "\00d7";
                        text-align: center;
                        margin-top: 20px;
                    }
                }

            }
            &__text-block{
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding: 0 30px;
                overflow: hidden;
                padding-top: 2px;
            }
        }
    }
    .home{
        text-align: center;
        line-height: 50px;
        height: 50px;
        #logo {
            display: block;
            margin: 0 auto;
            width: 65px;
        }
        #text-logo{
            display: block;
            margin-top: 10px;
            color: var(--logo-color);
            font-weight: bold;
            font-size: 20px;
            span{
                font-weight: 100;
                margin-left: 2px;
                letter-spacing: 2px;
            }
        }
    }
}

.detail-panel{
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    .search-wrap{
        display: flex;
        .icon-search{
            padding-top: 8px;
            margin-right: -35px;
            z-index: 2;
            svg {
                width: 14px;
                height: 14px;
                path{
                    fill: var(--link);
                }
            }
        }
        input{
            height: 30px;
            display: block;
            width: 100%;
            padding: 0;
            display: block;
            background: transparent;
            border-radius: 5px;
            border: none;
            color: var(--panel-text);
            font-weight: 300;
            font-size: 18px;
            margin-left: 10px;
            font-family: @font;
            &::placeholder{
                color: var(--panel-text-shade);
                font-size: 20px;
                letter-spacing: 0;
            }
            &:focus{
                outline: none;
            }
        }
    }
}