.modal-add-exclude {
    .content-wrap{
        display: flex;
        flex-direction: column;
        label{
            font-weight: bold;
            font-size: 18px;
        }
        textarea {
            font-family: @font;
            width: 90%;
            min-height: 200px;
            margin: 20px 5px;
            &.red-border {

            }
        }
    }
}

