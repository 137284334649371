.main-panel.settings{
    &.side-relative{
        .side-panel{
            position: relative;
        }
    }
    h2{
        color: white;
        font-size: @fontSizeH2;
        margin-left: 50px;
    }
    .tab-content{
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        position: relative;
        margin-top: 75px;
        padding: 40px;
        box-sizing: border-box;
        overflow: hidden;
        overflow-y: auto;
        width: 100%;
        .page-title {
            display: block;
            color: #fff;
            font-size: 15px;
            margin: 10px 0 25px 0;
            span{
                padding-left: 15px;
            }
        }
        p{color: gray;}
        span{
            &.val{
                color: gray;
                margin-left: 20px;
            }
        }
        &.info{
            span:not(.page-title){
                display: block;
                margin-bottom: 5px;
                color: var(--panel-text);
                &.val{
                    display: inline-block;
                    color: @brightYellow;
                    opacity: 0.9;
                }
            }
            .banner-wrap__banner--error{
                .banner-wrap__text-block span{
                    color: white;
                }
            }
            .banner-wrap__banner--success{
                .banner-wrap__text-block span{
                    color: black;
                }
            }
        }
        .side-by-side{
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            .side{
                min-width: 50%;
                margin-bottom: 20px;
            }
        }
        .input-wrapify();
        .line{
            display: flex;
            margin: 0 auto;
            color: @textColor;
            .label-title{
                color: @textColor;
            }
            label{
                padding-top: 11px;
            }
            input{
                width: 100px;
            }
        }
        .subtitle, .threshold-title{
            color: gray;
            border-bottom: 1px solid gray;
            margin: 30px 0 10px 0;
            padding-bottom: 5px;
        }
        .threshold-title{
            border: none;
        }
        .subsubtitle{
            color: gray;
            margin: 30px 0 10px 0;
            padding-bottom: 5px;
        }
        .highlight-blue{
            color: @textColor;
        }
        .dialog{
            border-radius: 5px;
            width: 500px;
            margin-bottom: 50px;
            h2{
                width: 100%;
                text-align: center;
                margin: 20px 0;
            }
            &.success{
                border: solid 2px @brightGreen;
            }
            &.error{
                border: solid 2px @red;
            }
            .fa{
                display: block;
                font-size: 80px;
                margin-top: 15px;
                &.fa-check-circle{
                    color: @brightGreen;
                }
                &.fa-times-circle{
                    color: @red;
                    color: @red;
                }
            }
        }
        .mt-100{margin-top: 100px;}
        .mt-20{margin-top: 20px;}
        .ml-20{margin-left: 20px;}
        .switches{
            .switch{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                .white-text{
                    color: rgba(255,255,255,0.7);
                    &.active{color: @brightYellow;}
                    &:hover{
                        cursor: pointer;
                    }
                }
                &-slider{
                    margin: 0 10px;
                }
            }
        }
    }

    .category-wrap{
        height: auto;
    }
    .category-dropdown{
        display: flex;
        flex-wrap: wrap;
        .left-title{
            padding-top: 9px;
        }
        .select2{
            margin-left: 20px;
            border: solid 1px;
            width: 50% !important;
            min-width: 200px !important;
        }
    }

    .butt-confirm{
        text-decoration: none;
        display:inline;
        margin: 0 20px 0 0;
        padding: 8px;
        float:left;
        text-transform: uppercase;
        width: 250px;
        height: 40px;
        .rounded-corners(20px);
        background: @brightYellow;
        color: @lightWhite;
        .btnAction();
    }

    .top-head-line{
        display: flex;
        justify-content: space-between;
        .add-ip-button{
            color: var(--panel-text);
            padding: 5px 15px;
            margin-top: 10px;
            .fa{margin-right: 10px;}
            &:hover{
                color: @brightYellow;
                border-color: @brightYellow;
                cursor: pointer;
            }
        }
    }

    .banner{
        &-wrap{
            display: flex;
            flex-grow: 1;
            flex-basis: 0;
            &__banner{
                height: 50px;
                width: 100%;
                display: flex;
                border-radius: 3px;
                margin-bottom: 20px;
                &--error{
                    color: white;
                    background: #FD7D7F;
                    button{
                        background: #FD565A;
                        &:hover{
                            background: #FD1C26;
                            color: white;
                        }
                    }
                    span{color: white;}
                }
                &--success{
                    background: #8AD996;
                    button{
                        color: black;
                        background: rgba(94, 164, 105, 0.80);
                        &:hover{
                            background: rgba(94, 164, 105, 1.00);
                        }
                    }
                    span{color: black;}
                }
                span{
                    flex-grow: 1;
                    text-align: left;
                    line-height: 35px;
                }
                button{
                    width: 50px;
                    border-radius: 0 3px 3px 0;
                    border: none;
                    &:focus{
                        outline: none;
                    }
                    &:hover{
                        cursor: pointer;
                    }
                    &:after{
                        font-size: 40px;
                        line-height: 35px;
                        width: 35px;
                        content: "\00d7";
                        text-align: center;
                        margin-top: 20px;
                    }
                }

            }
            &__text-block{
                display: flex;
                flex-direction: column;
                flex-grow: 1;
                padding: 0 30px;
                overflow: hidden;
                padding-top: 7px;
            }
        }
    }

    button.trigger {
        overflow: visible;
        white-space: nowrap;
        height: 30px;
        text-overflow: inherit;
        border: 1px solid rgba(155, 158, 174, .3);
        padding: 5px 15px;
        border-radius: 20px;
        box-sizing: border-box;
        text-align: center;
        color: var(--panel-text);
        background: transparent;
        margin-top: 10px;
        outline: none;
        i {
            color: @brightYellow;
            margin-right: 10px;
        }
        &:hover {
            color: @brightYellow;
            border-color: @brightYellow;
            cursor: pointer;
        }
    }
}
