.log {
    &-wrap{
        display: flex;
        flex-direction: column;
        align-content: flex-start;
    }
    &-entry{
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: solid 2px var(--table-row-second);
        margin: 20px 0;
        padding: 10px 20px;
        background: #363A4B;
        border-radius: 5px;
        width: 80%;
        .entry__val{ color: white; }
        .entry__desc{
            color: var(--panel-text);
            margin-right: 10px;
        }
        &__time, &__msg{
            display: flex;
            padding: 2px 10px;
        }
        &__msg {
            .entry__val{ color: @grayTextColor; }
        }
    }
}