.test{
    color: #19e880;
    color: #025AFF;
}

.footer{
    display: flex;
    justify-content: space-between;
    width: 95%;
    height: 100%;
    margin: 0 auto;
    padding: 10px 0;
    font-size: @fontSize13;
    .color-legend{
        display: flex;
        flex-direction: column;
        .headline{
            text-align: left;
            margin: 0;
            margin-bottom: 10px;
            white-space: nowrap;
            font-size: 12px;
        }
        svg, circle{
            width:10px;
            height: 10px;
            margin-bottom: 1px;
        }
        span{
            height: 15px;
            float:left;
        }
        .desc-pl{
            padding-top: 3px;
            margin-right: 10px;
        }
        .desc{
            margin-right: 5px;
            line-height: 14px;
        }
        .color-trigger {
            border-radius: 4px;
            margin-right: 10px;
            border: solid 1px transparent;
            padding: 2px 5px;
            * {
                pointer-events: none;
            }
            &:hover{
                span{color: var(--link)}
            }
            &.active{
                border: solid 1px var(--link);
                span{color: var(--link)}
            }
            .color-box{
                padding: 0;
                line-height: 17px;
                margin-right: 5px;
            }
            .desc{color: var(--panel-text)}
        }
        .line{
            display: flex;
            flex-direction: row;
        }
        .show-hide{
            color: var(--link);
            text-align: left;
            width: 100%;
            margin: 0;
            white-space: nowrap;
            &.hidden{
                display: none;
            }
            &:hover{
                color: var(--link);
            }
        }
        .stat-table{
            width: 100%;
            display: flex;
            background: var(--panel-background);
            flex-wrap: wrap;
            &.hidden{
                display: none;
            }
            .stats{
                display: flex;
                flex-direction: column;
                width: fit-content;
                margin: 15px 25px 15px 0;
                span{
                    margin-bottom: 5px;
                }
                .val{
                    margin-right: 10px;
                    margin-left: 5px;
                    color: var(--panel-text);
                }
            }
            table{
                border-collapse: collapse;
                margin-top: 15px;
                th,td{
                    color: var(--panel-text);
                    text-align: left;
                    font-weight: 300;
                }
                th{
                    text-align: center;
                }
                td{
                    padding: 2px 10px;
                }
                .color, .count, .count-in-perc{
                    width: 70px;
                    .desc{
                        margin-left: 5px;
                        float: inherit;
                    }
                }
                thead{
                    tr{
                        border-bottom: 1px solid rgba(155,158,174,.3);
                    }
                }
                tr{
                    &.light{
                        background: var(--table-row-second);
                    }
                    &:first-of-type{
                        td{
                            .desc{
                                margin-left: 13px;
                                float: inherit;
                            }
                        }
                    }
                    td.first{border-radius: 3px 0 0 3px}
                    td.last{border-radius:  0 3px 3px 0}
                }
                .border-right{
                    border-right: 1px solid rgba(155,158,174,.3);
                }
                .count{
                    padding-left: 10px;
                }
                .color{
                    width: 50px;
                }
                .count-in-perc{
                    width: 50px;
                }
            }
        }
        .color-trigger, .stat-trigger{
            line-height: 20px;
        }
        .stat-trigger{
            &:hover{
                cursor: pointer;
                .show-hide{
                    text-decoration: underline;
                }
            }
        }
        .legend-toggle{
            display: flex;
            a{display: flex}
        }
    }

    .fa-cog, .fa-external-link-square{
        font-size: @fontSizeStandard;
        margin-right: 10px;
        color: gray;
    }

    .footer-block{
        display: inline-block;
        margin: 0;
        margin-right: 15px;
        position: relative;
        float: left;
        font-size: @fontSizeStandard;
        span{
            color: var(--title);
        }
        .span-info{
            white-space: nowrap;
            margin-right: 20px;
            color: var(--title);
            .val{
                margin-left: 5px;
                font-size: @fontSize13;
                color: var(--panel-text);
            }
        }
        &.legend-block{
            font-size: @fontSize13;
        }
        &.button-block{
            padding-top: 15px;
            padding-bottom: 15px;
            span:hover{
                color: white;
            }
        }
        &.stat-block{
            display: flex;
            flex-wrap: wrap;
            font-size: @fontSize12;
            margin: 0;
            padding: 0;
            align-items: flex-end;
            max-height: 40px;
        }
        .fa-info-circle{
            color: var(--panel-text);
            font-size: 15px;
            margin: 0;
        }
    }
}