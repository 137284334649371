.status-legend{
    height: 40px;
    width: 90%;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: center;
    .color-legend{
        display: flex;
        color: gray;
        margin-right: 15px;
        width: 150px;
        justify-content: center;
        .mess{
            margin: 0 0 0 5px;
        }
        svg, rect{
            width: 15px;
            height: 15px;
            margin-top: 5px;
        }
    }
}

#mother.empty-config{
    .detail-status.main-panel{
        left:0;
        width: 100%;
        position: relative;
        margin: 100px auto;
    }
}

.tab-content.status{
    .search-wrap{
        display: flex;
        justify-content: center;
        .fa-search{
            display: block;
            font-size: 18px;
            color: @brightYellow;
            border-radius: 5px;
            padding-top: 6px;
            height: 27px;
            margin-right: -25px;
            padding-left: 5px;
            padding-right: 10px;
        }
        input{
            height: 30px;
            text-align: center;
            margin-left: -10px;
            display: block;
            max-width: 350px;
            padding: 0;
            background: transparent;
            border-radius: 5px;
            border: none;
            color: @lightWhiteActive;
            font-weight: 300;
            font-size: 18px;
            margin-bottom: 15px;
            &::placeholder{
                color: var(--panel-text-shade);
                font-size: 20px;
                letter-spacing: 0;
            }
            &:focus{
                outline: none;
            }
        }
    }
    td.status-ipv{
        display: flex;
        justify-content: flex-start;
        padding: 0;
        align-items: center;
        height: 35px;
        .ipv{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 25px;
            height: 25px;
            border-radius: 3px;
            margin: auto 4px;
            font-weight: bold;
            &:hover{
                cursor: pointer;
            }
            &--red{
                background: red;
            }
            &--green{
                background: green;
            }
        }
    }
}