
.input-wrapify(){
    .form-content-wrap{
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
    }
    .input-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: start;
        margin: 0;
        margin-bottom: 15px;
    }
    input{
        .styleGenericInput();
        background: transparent;
        border: 1px solid #5A5D71;
        border-radius: 5px;
        color: #C9CBD6;
        width: 100%;
        padding: 0 10px;
        margin: 0;
        &.long-input{max-width: 500px;}
        &.short-input{max-width: 200px;}
    }
    .textarea-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: start;
        margin: 0;
        margin-bottom: 15px;
        textarea{
            .styleGenericInput();
            background: #363A4B;
            color: white;
            padding: 10px;
            height: 300px;
        }
    }
    .select-wrap{
        display: flex;
        flex-direction: column;
        margin: 0;
        margin-bottom: 15px;
        .select2-selection__rendered{
            color: white;
            padding: 0 10px;
        }
        .select2-container--default .select2-selection--single {
            box-shadow: 0 0 2px var(--panel-text-shade);
            border: 1px solid #5a5d71;
            border-radius: 5px;
            height: 40px;
        }
    }
    .input-wrap, .textarea-wrap, .select-wrap {
        .label-title {
            color: white;
            margin-bottom: 5px;
            text-transform: uppercase;
            font-size: 15px;
        }
    }
}