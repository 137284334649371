.main-panel.settings{
    .tab-content.alerts{
        .content-wrap{
            display: flex;
            width: 100%;
            flex-direction: column;
            &>div{
                flex-grow: 1;
            }

        }
        .alert-form-content{
            display: flex;
            flex-direction: column;
            padding: 20px 30px;
            border-radius: 5px;
            margin-top: 35px;
        }

        .input-wrap input{ background: var(--main-content-background); }
        .textarea-wrap textarea{ background: var(--main-content-background); }
        .modal-wrapbox{
            display: flex;
            .modal-trigger{
                margin: 0 20px;
            }
        }
    }
}