.side-panel{
    ::-webkit-scrollbar-track{background: rgba(0, 0, 0, 0);}
    .servers{
        text-align: center;
    }
    font-size: @fontSize;
    display: flex;
    flex-direction: column;
    z-index: 2;
    color: @sideText;
    background: rgba(25,27,46,1);
    box-sizing: border-box;
    a {
        color: @sideText;
        display: block;
        font-size: @fontSize13;
        text-decoration: none;
        text-overflow: ellipsis;
        width: 90%;
        white-space: nowrap;
        overflow: hidden;
        &.active{
            color: @brightYellow;
        }
    }
    .home {
        display: inline-block;
        width: 90%;
        margin: 10px;
    }
    ul {
        display: block;
        padding: 0;
        list-style: none;
        margin-bottom: 20px;
        .fa {
            padding: 10px 0;
            margin: 10px 0 0;
            span {
                margin-left: 7px;
            }
        }
        .fa-chevron-down::before, .fa-chevron-up::before {
            margin-right: 7px;
        }
    }
    .add-ip, .add-category{
        &:hover {
            color: @sideTextHover;
            cursor: pointer;
        }
    }
    &.hidden{
        display: none;
    }
    @media (max-width: 850px) {
        display: none;
        position: relative;
        margin: 0 auto;
        min-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        color: @lightGrey;
        a {
            color: @lightGrey;
            text-decoration: none;
        }

        #logo, #search, #login-info {
            display: none;
        }

        #sidebar {
            width: 90%;
            li {
                margin-top: 15px;
                border-bottom: solid 1px dimgray;
                padding-bottom: 20px;
            }
        }
    }
}
.home {
    text-align: center;
    line-height: 50px;
    height: 50px;

    #logo {
        display: block;
        margin: 0 auto;
        width: 65px;
    }

    #text-logo {
        display: block;
        margin-top: 10px;
        color: var(--logo-color);
        font-weight: bold;
        font-size: 20px;

        span {
            font-weight: 100;
            margin-left: 2px;
            letter-spacing: 2px;
        }
    }
}