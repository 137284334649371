.tab-content.users{
    h1{
        font-size: 18px;
        color: white;
        span{
            color: #9B9EAE;
            font-size: 13px;
            margin: 0 10px;
        }
    }
    .trigger{
        overflow: visible;
        white-space: nowrap;
        height: 30px;
        text-overflow: inherit;
        border: 1px solid rgba(155,158,174,.3);
        padding: 5px 15px;
        border-radius: 20px;
        box-sizing: border-box;
        text-align: center;
        color: #9b9eae;
        background: transparent;
        margin-top: 5px;
        margin-left: 20px;
        outline: none;
        i{
            color: @brightYellow;
            margin-right: 5px;
        }
        &:hover{
            color: @brightYellow;
            border-color: @brightYellow;
            cursor: pointer;
        }
    }
    .top{
        display: flex;
        justify-content: space-between;
    }
    .add-user{
        border-radius: 3px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        margin: 40px auto;
        max-width: 1100px;
        padding: 10px 5px;
        &-inner{
            display: flex;
            max-width: 800px;
            width: 95%;
            justify-content: space-between;
            flex-wrap: wrap;
            height: 100%;
            box-sizing: border-box;
            padding: 10px 0;
            h3{
                color: #2E324F;
                margin: 20px 10px 20px 0;
                line-height: 18px;
            }
            form{
                width: auto;
                .input-wrap{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin: 0;
                    label{
                        color: #9B9EAE;
                        font-size: 12px;
                        margin: 25px 15px 0 0;
                    }
                    input{
                        width: 300px;
                        margin: 10px  15px 0 0;
                        border-color: #C9CBD6;
                        color: #5a5d71;
                    }
                    button[type=submit]{
                        text-decoration: none;
                        padding: 5px 25px;
                        border-radius: 20px;
                        background: #adc65c;
                        color: #fff;
                        border: none;
                        height: 40px;
                        font-size: 18px;
                        font-weight: 300;
                        margin-top: 10px;
                        &:focus{
                            outline: none;
                        }
                        &:hover{
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
    table{
        span,i { pointer-events: none}
        tbody{
           .permission{
               &-wrap{
                   padding: 5px 10px;
                   margin: 0 5px;
               }
               span{
                   &.active{
                       background: #24263A;
                       border-radius: 14px;
                   }
               }
               .fa-check{
                   color: #96B233;
                   padding-right: 6px;
                   .lightIcon(1.5px, #24263A);
               }
           }
            .password-reset{
                span{
                    padding: 5px 10px;
                    border: solid 1px transparent;
                }
                &:hover{
                    span{
                        border: solid 1px @brightYellow;
                        border-radius: 15px;
                    }
                }
            }
            .locked-row{
                *{
                    pointer-events: none;
                }
            }
        }
    }
}