.modal-add-alert{
    //.input-wrapify();
    .select-wrap{
        margin-bottom: 20px;
    }
    .input-wrap{
        input[type=text]{
            padding: 0 15px;
        }
        &__short{
            width: 175px;
            margin-right: 20px;
        }
    }
    .input-box{
        display: flex;
        flex-wrap: wrap;
        span{
            margin-right: 10px;
        }
    }
    .select-box{
        display: flex;
        flex-wrap: wrap;
        .select-wrap{
            &:first-of-type{
                margin-right: 20px;
            }
            .select2-container {
                width: 200px !important;
            }
        }
    }
    .textarea-wrap{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        margin: 0;
        textarea{
            .styleGenericInput();
            padding: 10px;
            height: 150px;
        }
        &--small{textarea{height: 60px}}
    }
}
