#sidebar {
    width: 100%;
    font-size: @fontSizeStandard;
    max-height: calc(~'100% - 50px');
    position: relative;
    margin-bottom: 30px;
    overflow-y: scroll;
    padding: 0 30px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    padding-bottom: 30px;
    background: transparent;
    display: flex;
    flex-direction: column;
    flex: 10000000 0 40%;
    .top-ul{
        padding: 0;
        width: 100%;
        margin: 0 auto;
        border: none;
        margin-top: 0px;
        li{
            width: 100%;
            margin: 0 auto;
            .first-line{display: flex;}
        }
        &.open{
            display: block;
        }
        &.close{
            display: none;
        }
    }
    a {
        width: 100%
    }
    .section {
        display: flex;
        justify-content: space-between;
        margin: 5px auto;
        margin-bottom: 9px;
        margin-top: 6px;
        color: @sideText;
        i{padding: 0;margin: 0;}
        .fa-list-ul{
            color: @sideBrightText;
            margin-right: 8px;
            margin-top: 2px;
            font-size: @fontSize13;
        }
        .title{
            flex-grow: 1;
            font-weight: 300;
            color: @sideText;
            font-size: @fontSize13;
            overflow-x: hidden;
            svg{
                width: 10px;
                height: 10px;
                margin-bottom: 3px;
            }
        }
        .right-bubble{
            border: solid 1px @sideBrightText;
            border-radius: 20px;
            margin-left: 8px;
            padding: 0 4px 0 8px;
            height: 17px;
            line-height: 17px;
            font-size: @fontSize12;
            display: flex;
            .chevron{
                display: block;
                color: @sideTextActive;
                width: 14px;
                height: 17px;
                border-radius: 50%;
                margin: 0px 0 1px 5px;
                svg {
                    width: 12px;
                    height: 17px;
                    path{
                        fill: @sideBrightText;
                    }
                }
                &.up{
                    padding-left: 2px;
                    margin-top: 0;
                    transform: rotate(180deg);
                }
            }
            &.active{
                color: white;
                border-color: @sideTextHover;
                svg path{ fill: @sideTextActive;}
            }
        }
        &:hover{
            color: @sideTextHover;
            .title, .fa, .right-bubble{
                color: @sideTextHover;
                border-color: @sideTextHover;
            }
        }
    }
    .category-content{
        border-left: 1px solid rgba(155,158,174,0.1);
        margin-bottom: 10px;
        margin-left: 5px;
        li{
            margin: 0 0 0 auto;
            width: 95%;
        }
    }
    .ip-li{
        display: flex;
        color: @sideBrightText;
        font-weight: 300;
        margin-bottom: 10px;
        a{
            margin-bottom: 5px;
            line-height: 20px;
        }
        svg{
            margin: 4px 3px 0 1px;
            height: 11px;
            width: 11px;
        }
        :hover{
            color: @sideTextHover;
        }
    }
    .topControl{
        display: flex;
        justify-content: center;
        align-items: center;
        .search-as{
            width: 90%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 15px auto;
            input{
                border-radius: 3px;
                background: var(--main-content-background);
                height: 30px;
                border: none;
                outline: none;
                box-shadow: none;
                padding: 0 10px;
                width: 80%;
                color: #fff;
                text-align: center;
                font-size: 18px;
            }
        }
        .modal-wrap-add-autogroup{
            padding: 0;
            a,i{margin: 0}
        }
    }
}