.modalProbeSettings{
    .trigger{
        border: none;
        padding: 0;
        width: 100%;
        color: rgba(255,255,255,0.5);
        text-align: center;
        i{font-size: 15px;}
        &:hover{
            cursor: pointer;
        }
    }
}