#search {
    font-size: @fontSizeStandard;
    font-family: @font;
    height: 30px;
    padding: 0 5px;
    border-radius: 3px;
    flex: 1 0 100px;
    margin: 0 10px;
    margin-bottom: 6px;
    &:hover{cursor:pointer;}
    input{
        padding-left: 35px;
        &:focus{
            background: var(--search-focus);
        }
    }
    .btn-close{
        display: block;
        padding: 0;
        text-transform: uppercase;
        background: transparent;
        margin: 0 15px;
        font-size: 15px;
        text-align: center;
        color: @lightWhiteActive;
        border: none;
        &:hover{
            //color: @brightYellow;
            color: rgba(255,0,0,0.7);
            cursor: pointer;
        }
        &:focus{
            outline: none;
        }
    }
    .options{
        display: flex;
        justify-content: space-between;
        flex: 0 0 50px;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 20px;
        .inp-check{
            padding-top: 10px;
        }
    }
    .result{
        background: @topPanelColor;
        position: absolute;
        display: flex;
        flex-direction: column;
        flex: 1 0 100%;
        z-index: 10;
        left: 0;
        top: 50px;
        width: 100%;
        height: calc(~"100vh - 50px");
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 20px 40px;
        box-sizing: border-box;
        .search-tips{
            margin: 0 auto;
            .content{
                color: gray;
                .title{
                    color: var(--panel-text);
                }
            }
        }
        .fa-times{
            margin-left: 10px;
            &:hover{
                color: red;
            }
        }
        h2,h3{
            text-align: center;
            color: var(--panel-text);
            font-weight: 400;
        }
        table{
            width: 100%;
            color: rgba(255,255,255,0.6);
            padding: 20px;
            border-collapse: collapse;
        }
        thead{
            tr{
                font-size: @fontSizeStandard;
                th{
                    font-weight: normal;
                    text-align: left;
                    padding-left: 10px;
                    padding-bottom: 10px;
                    .navigation-arrow{
                        fill: transparent;
                        opacity: .3;
                        width: 9px;
                        margin-left: 10px;
                        -webkit-transition: all .3s;
                        transition: all .3s;
                        width: 16px;
                        height: 10px;
                        opacity: 1;
                        pointer-events: none;
                        &.descend{
                            transform: rotate(180deg);
                        }
                    }
                    &.active{
                        color: white;
                        .navigation-arrow{
                            fill: @brightYellow;
                        }
                    }
                    &:hover{
                        cursor: pointer;
                        color: white;
                    }
                }
            }
        }
        tbody{
            tr{
                height: 25px;
                &.light{td{background: rgba(255,255,255,0.05)}}
                &.dark{td{background: transparent}}
                &:hover{
                    cursor: pointer;
                    td{background: rgba(255,255,255,0.15)}
                }
            }
            tr.active{
                color: white;
            }
            tr:hover{
                color: white;
                cursor: pointer;
            }
            .pl{
                svg{
                    width: 15px;
                    height: 14px;
                }
            }
            td{
                padding: 0;
                padding-left: 10px;
                padding-right: 10px;
                white-space: nowrap;
                font-family: Helvetica;
                font-size: 13px;
                color: rgba(255,255,255,0.60);
                letter-spacing: 0;
                line-height: 30px;
                user-select: none; /* CSS3 (little to no support) */
                &.first{border-radius: 5px 0 0 5px;}
                &.last{border-radius: 0 5px 5px 0;}
                &.detail{color:@brightYellow}
                &.graph{
                    width: 100%;
                    height: 200px;
                    .graph-wrap{
                        margin: 20px auto;
                    }
                }
                .inp-check{
                    input+span{
                        height: 17px;
                    }
                }
                &:hover{
                    .inp-check input+span{
                        color: @brightYellow;
                        &:before{
                            border-color: @brightYellow;
                        }
                    }
                }

            }
            .show-graph{
                i{
                    pointer-events: none;
                }
            }
        }
        @media(max-width: 1000px){
            thead{
                th:nth-child(6), th:nth-child(7), th:nth-child(8){
                    display: none;
                }
            }
            tbody{
                td:nth-child(6), td:nth-child(7), td:nth-child(8){
                    display: none;
                }
            }
        }
        @media(max-width: 800px){
            thead{
                th:nth-child(5){
                    display: none;
                }
            }
            tbody{
                td:nth-child(5){
                    display: none;
                }
            }
        }
        .no-search-results{
            width: 100%;
            text-align: center;
            span{
                color: rgba(155,158,174,.3);
                font-weight: 300;
                display: block;
                width: 200px;
                margin: 0 auto;
                margin-top: 30px;
                border: solid var(--panel-text-shade);
                border-radius: 20px;
                padding: 5px 20px;
                color: red;
                font-weight: 300;
            }
        }
    }
}