.settings-box{
    display: flex;
    justify-content: center;
    .switch{
        display: flex;
        align-items: center;
        margin: auto 20px;
        .switch-slider{
            margin: 0px 7px;
        }
        span{
            font-size: @fontSize;
            line-height: 19px;
            color: var(--graph-text);
            .linkAction();
            &.active{
                color: var(--link);
            }
        }
    }
}