.settings-table {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow-x: auto;
    table{
        width: 100%;
        color: rgba(255,255,255,0.6);
        padding: 20px;
        border-collapse: collapse;
        .w-25{width: 25px}
        .w-50{width: 50px}
        .w-100{width: 100px}
        .w-150{width: 150px}
        .w-200{width: 200px}
        thead{
            tr{
                th{
                    margin-right: 20px;
                    color: @darkGrey;
                    font-size: @fontSize12;
                    font-weight: normal;
                    text-align: left;
                    padding: 10px 0 10px 5px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &.text-center{
                        text-align: center;
                    }
                }
            }
        }
        tbody{
            tr{
                height: 35px;
                &.light{td{background: rgba(255,255,255,0.05)}}
                &.dark{td{background: transparent}}
                &:hover{
                    td{background: rgba(255,255,255,0.15)}
                }
                td{
                    padding: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    white-space: nowrap;
                    font-size: 13px;
                    color: rgba(255,255,255,0.60);
                    letter-spacing: 0;
                    line-height: 30px;
                    &.first{border-radius: 5px 0 0 5px;}
                    &.last{border-radius: 0 5px 5px 0;}
                    &.detail{color:@brightYellow}
                    i{
                        font-size: 18px;
                        &.fa-trash, &.fa-lock { color: #F54949; }
                        &.fa-pencil, &.fa-key, &.fa-long-arrow-right { color: RGBA(253, 182, 49, 0.9); }
                        &.fa-times{ color: RGBA(255,87,61,.9); }
                        &.fa-bell-o{
                            font-size: 15px;
                            color: green;
                        }
                    }
                    &.icon{
                        padding-left: 12px;
                        svg{
                            display: block;
                            width: 15px;
                            height: 15px;
                        }
                    }
                    &:hover{
                        color: white;
                    }
                    &.hover-action:hover{
                        cursor: pointer;
                    }
                    span{
                        &.yell{
                            color: @brightYellow
                        }
                    }
                }
                &.red-confirm {
                    background: rgba(255, 87, 61, 0.6);
                    td {
                        text-align: center;
                        font-size: 15px;
                        font-weight: bold;
                        &.action {
                            &:hover {
                                cursor: pointer;
                                color: white;
                            }
                        }
                    }
                }
                &.yellow-confirm {
                    background: RGBA(253, 182, 49, 1.00);
                    td {
                        text-align: center;
                        font-size: 15px;
                        font-weight: bold;
                        color: black;
                        span {
                            color: black;
                        }
                        &.action {
                            &:hover {
                                cursor: pointer;
                                color: black;
                            }
                        }
                    }
                }
            }
        }
    }
}