.control-panel-wrap{
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    min-height: 65px;
    @media(max-width: 591px){
        min-height: 130px;
    }
    .control-panel, .settings-box{
        border-radius: 3px;
        box-shadow: var(--panel-shadow);
        box-sizing: border-box;
        background: var(--panel-background);
        padding: 10px 15px;
        margin-bottom: 20px;
        min-height: 45px;
    }
    .control-panel{
        display: flex;
        overflow: hidden;
    }

    .title-wrap {
        display: flex;
        height: 25px;
        line-height: 25px;
        overflow: hidden;
        a:nth-child(1){
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .ip-info {
            color: var(--title);
            margin-right: 20px;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            text-transform: uppercase;

            &.no-title {
                color: var(--graph-text);
            }
        }

        .as-info {
            font-size: @fontSize13;
            color: var(--graph-text);
            margin-right: 10px;
            .val{
                margin-left: 5px;
            }
            .autogroup{
                margin-left: 15px;
                font-size: 13px;
            }
        }
    }
    .cog_disabled{
        color: @brightYellow;
        margin-left: 20px;
        margin-top: 5px;
    }
}

.lookingGlass {
    display: flex;
    align-items: center;
    justify-items: center;
    &>div {
        height: 18px;
        padding: 2px 3px 2px;
        border-radius: 2px;
        margin-top: 2px;
        img {
            width: 17px;
            height: 17px;
        }
    }
    &:hover>div {
        color: #ADC65C;
        background: rgba(173,198,92,0.2);
    }
}
