@modalTextColor: #43485E;
@modalTextColor: #43485E;
@modalBorderColor: #C9CBD6;

.modal{
    font-size: @fontSizeStandard;
    color: @modalTextColor;
    box-sizing: border-box;
    background: white;
    text-align: left;
    transition: background 0.3s, color 0.3s;
    padding: 10px 30px;
    overflow: hidden;
    border-radius: 10px;
    overflow-y: scroll;
    height: auto;
    .rounded-corners(10px);
    .butts{
        display: flex;
        padding-top: 20px;
        justify-content: flex-end;
        &.multi{justify-content: space-between;}
    }
	h1{
        color: @modalTextColor;
		font-size: 30px;
	}
    h2 {
        color: @modalTextColor;
        font-size: @fontSizeH2;
        font-weight:normal;
        padding-bottom: 40px;
        margin: 0;
    }
	form {
		label:not(.inline, .inp-check, .switch-slider){
			display: block;
			padding: 5px 0;
			span{text-transform: uppercase}
		}
		input[type='text'],input[type='number']{
            .styleGenericInput();

		}
	}
	.modal-confirm{
		text-decoration: none;
		display:inline;
		margin: 0 20px 0 0;
		padding: 8px;
		float:left;
		.btnConfirm(26%,@brightYellow,rgba(255,255,255,0.7));
        background: @brightYellow;
        width: 250px;
        color: white;
        border: none;
        height: 40px;
        font-size: 18px;
        font-weight: 300;
        &:disabled{
            background: rgba(0,0,0,0.2);
            color: rgba(0,0,0,0.7);
        }
	}

	.modal-close{
		transition: color 0.2s;
		color: inherit;
        width: 35px;
        height: 35px;
        cursor: pointer;
        transition: color 0.2s;
        text-decoration: none;
        color: #95979c;
        border: 0;
        outline: 0;
        background: transparent;
        position: absolute;
        right: 30px;
        top: 15px;
        z-index: 10;
		&:hover, &:focus{
			transition: background 0.5s, color 0.4s;
			color: darken(@modalColor,50%);/*#2b2e38;*/
		}
		&::before{
			font-size:@fontSizeH2 + 5;
            font-family: Arial, "Helvetica CY", "Nimbus Sans L", sans-serif !important;
            font-size: 40px;
            line-height: 35px;
            width: 35px;
            content: "\00d7";
            text-align: center;
            margin-top: 20px;
        }
	}

	.category-radio-list{
		.columns(3,0);
		padding-left:@modalSidePadding;
		min-height:60px;
		li{

			text-align: left;
			list-style-type: none;
		}
		input[type=radio]{
			display:none;
		}
		label{
			text-transform:none;
			display:inline-block;
			padding:0;
			color:@modalColor;
			height:100%;
			margin:5px 0;
			&:hover{
				color:@white;
			}
		}
		input[type=radio] + label,
		input[type=radio]:checked + label{
			cursor:pointer;
			margin:0 30px 10px 0;
			min-width:100px;
		}
		input[type=radio] + label::before,
		input[type=radio]:checked + label::before {
			font-family: FontAwesome;
			font-size:@fontSizeStandard + 3;
			padding-right:10px;
			vertical-align:top;
		}
		input[type=radio] + label::before{
			content:"\f1db";
		}
		input[type=radio]:checked + label::before{
			content: "\f192";
		}
	}
    .inp-check input+span{
        color: @modalTextColor;
        &:before{
            border-color: @modalBorderColor;
        }
    }
    a:hover{
        color: @brightYellow;
    }
    .select2-container{
        .select2-selection{
            background: white;
            border: 1px solid @modalBorderColor;
        }
        .select2-dropdown{
            color: @modalTextColor;
        }
    }
    .confirm-dialog{
        .modal-confirm{
            margin: 0 auto;
            height: 30px;
            width: 100px;
            line-height: 15px;
            margin: 10px auto;
            float: none;
        }
    }
    .modal-loader{
        position: absolute;
        background: rgba(255,255,255,0.7);
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        .position{
            position: relative;
            background: rgba(255,255,255,0.8);
            top: calc(~"50% - 60px");
        }
        .sk-fading-circle{
            margin: 0 auto;
            .sk-circle:before{
                background-color: black;
            }
        }
    }
    @media (max-width: 1700px) {
		.category-radio-list {
			.columns(2, 20);
		}
	}

	@media (max-width: 1250px) {
		.category-radio-list {
			.columns(1, 20);
		}
	}
}

/*Add new IP modal*/
.modalAddIp.modal-wrap{
    width: 100px;
}
.modal-wrap{
    display: flex;
    a{
        overflow: visible;
        white-space: nowrap;
        height: 30px;
        text-overflow: inherit;
        border: 1px solid rgba(155,158,174,.3);
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        width: fit-content;
        box-sizing: border-box;
        text-align: center;
        max-width: fit-content;
        color: var(--panel-text);
        .fa-plus{
            color: @brightYellow;
            margin-right: 6px;
        }
        &:hover{
            color: @brightYellow;
            border-color: @brightYellow;
        }
    }
    .ip-settings-trigger {
        color: var(--link);
        border: none;
        padding: 0;
        height: 25px;
        line-height: 25px;
        margin: 1px 10px 0 10px;
        i{margin-right: 10px;}
        .linkAction();
        .fa {
            color: var(--link);
            font-size: 16px;
            margin-right: 5px;
            line-height: 25px;
        }
    }
    .cat-settings-trigger{
        padding: 0;
        margin: 0;
        margin-right: 10px;
        .linkAction();
        border: none;
        color: #9b9eae;
        &.with-border{
            display: flex;
            border: 1px solid rgba(155,158,174,.3);
            padding: 5px 15px;
            i.fa{
                margin-right: 10px;
                margin-top: 0;
                padding-top: 2px;
            }
        }
        i.fa{
            padding: 0;
            color: #adc65c;
            margin: 6px 0 0;
            font-size: 15px;
            opacity: .5;
        }
        &:hover{
            text-decoration: none;
            i.fa{
                opacity: 1;
                color: @brightYellow;
            }
        }

    }
}

.modal-wrap-add-autogroup{
    justify-content: center;
    padding: 15px;
    a{margin: 0}
}

.modal-add-ip, .modal-add-slave{
    display: flex;
    flex-direction: column;
    .butts{
        display: flex;
        justify-content: space-between;
        .inp-check{
            margin-top: 5px;
            color: @modalTextColor;
        }
    }
    .input-box{
        display: flex;
        span{
            margin-right: 10px;
        }
        .add-ip-address-box{
            width: 200px;
        }
        .add-ip-port-box{
            float:left;
            width:150px;
        }
        .add-ip-alias-box{
            flex-grow:1;
            float:left;
            min-width: 200px;
        }
    }
    .copy-input-box{
        display: flex;
        flex-direction: column;
        .top-title{
            padding: 10px 0;
        }
    }
    @media (max-width: 900px) {
        .input-box{
            flex-direction: column;
            justify-content: center;
        }
    }
}

.modal-add-ip{
    .switch {
        display: flex;
        margin: 10px 0;
        label {
            margin: 0 10px;
        }
    }
    .add-ip-address-box .label-title{
        span {margin-right: 20px;}
    }
    input::placeholder{
        color: rgba(0,0,0,0.2);
    }
}

.modal-add-slave{
    .howto-box{
        border-top: solid 2px @lightWhite;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        .instruction-trigger{
            display: block;
            padding: 0px 20px;
            float: left;
            position: relative;
            margin: 0 auto;
            top: -30px;
            z-index: 2;
            background: #f4f5f7;
            margin-top: 20px;
            width: 100%;
            text-align: center;
            height: 40px;
            border-radius: 3px;
            box-sizing: border-box;
            .fa:before{
                position: relative;
                top: 4px;
            }
            &:hover{
                color: @brightYellow;
            }
        }
        .howto{
            margin-top: -30px;
            background: #f4f5f7;
            &.hidden{
                display: none;
            }
            .list{
                li:first-child{
                    display: flex;
                    flex-direction: column;
                }
                li{
                    list-style: none;
                    margin-bottom: 10px;
                    line-height: 30px;
                }
            }
        }
        .fa-angle-down, .fa-angle-up{
            font-size: 30px;
            margin-left: 15px;
            font-weight: bold;
        }
        .fa-download{
            line-height: 30px;
            margin-left: 10px;
            font-size: 20px;
            color: @textColor;
            &:hover{
                color: @brightYellow;
            }
        }
    }
    .architecture-dropdown{
        height: 30px;
        padding-top: 5px;
        .select2-container{
            width: 150px !important;
            margin: 0 15px;
            .select2-selection--single{
                padding: 3px 2px !important;
            }
        }
    }
    .left-title{
        padding: 5px 15px 0 17px;
        .link{
            color: @textColor;
            .linkAction();
        }
    }
}

.modal-set-ip{
    min-width:400px;
    .top-line{
        display: flex;
        a.favourite-selection{
            margin-left: 30px;
            i{
                font-size: 26px;
                padding-top: 2px;
            }
        }
    }
    .alias-change{
        span{
            margin-right: 20px;
        }
        input{
            text-align: center;
        }
    }
    .delete-ip{
        color: @modalTextColor;
        padding-top: 5px;
        .trash-ip {
            padding:5px 0;
            cursor:pointer;
            &:hover{
                color:black;
            }
            span, i {
                pointer-events: none;
            }
            .fa-trash-o{
                font-size: 23px;
                margin-right: 10px;
            }
        }
        #delete-ip-confirm{
            margin-left:15px;
            .btn{
                cursor:pointer;
                padding:6px 14px;
                text-transform: uppercase;
                &:hover{
                    padding:5px 13px;
                    border:1px solid darken(@modalColor,10%);
                    .rounded-corners(3px);
                }
            }
            .btn-delete{
                &:hover{
                    border: solid 1px red;
                    color: red;
                }
            }
        }
    }
}

.modal-set-cat{
    min-width:400px;
    @set-cat-background: rgba(0,0,0,0.1);
    .tab-slider(100%, black, #adc65c, #E8E9EF);
    .tab-content{
        border-top: solid 1px @set-cat-background;
        width: 100%;
        display: flex;
        padding: 10px;
        box-sizing: border-box;
    }
    .alias-change{
        width: 400px;
        span{
            margin-right: 20px;
        }
        input{
            text-align: center;
        }
    }
    input[type=range]::-webkit-slider-runnable-track {
        background: rgba(43,46,65,0.6);
    }
    .delete-cat{
        padding-top: 5px;
        .trash-ip {
            padding:5px 0;
            cursor:pointer;
            &:hover{
                color:black;
            }
            .fa-trash-o{
                font-size: 23px;
                margin-right: 10px;
            }
        }
        #delete-cat-confirm{
            margin-left:15px;
            .btn{
                cursor:pointer;
                padding:6px 14px;
                text-transform: uppercase;
                &:hover{
                    padding:5px 13px;
                    border:1px solid darken(@modalColor,10%);
                    .rounded-corners(3px);
                }
            }
            .btn-delete{
                &:hover{
                    border: solid 1px red;
                    color: red;
                }
            }
        }
    }
    .threshold{
        display: flex;
        flex-direction: column;
        width: 100%;
        h4{margin-top: 0;}
        p{
            color: gray;
            font-size: 14px;
        }
        &-wrap{
            display: flex;
            flex-wrap: wrap;
        }
        &-input{
            display: flex;
            flex-direction: column;
            width: 180px;
            margin-right: 40px;
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    button:disabled, button:disabled:hover{
        background: gray;
        color: @textColor;
    }
}

#del-ip-form{
    input{
        display: block;
        width: 200px;
    }
}



@media (max-width: @mobileMax) {
	.modal{
		width:70%;
	}

	.modal-confirm,
	.modal-cancel{
		padding:10px;
	}
}

.modal-public-url{
    h2{
        padding: 0;
        margin-top: 25px;
    }
    h3{
        margin-top: 10px;
        margin-bottom: 30px;
        color: @modalTextColor;
        font-weight: 400;
    }
    .content{
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
        input[type=text]{
            margin: 0;
        }
    }
    .more-trigger{
        color: @brightYellow;
        margin: 15px 0;
        .linkAction();
        font-size: 13px;
        letter-spacing: 0;
        line-height: 30px;
        i{
            margin-left: 5px;
        }
    }
    .line{
        width: 100%;
        display: flex;
        height: 30px;
        &.high{
            height: 50px;
            .title{padding-top: 10px}
        }
        .title{
            width: 200px;
            span{
                color: @modalTextColor;
                font-size: 13px;
                text-transform: uppercase;
            }
        }
        .set-option{
            .switch {
                display: flex;
                span{
                    color: @modalTextColor;
                    text-transform: uppercase;
                    .linkAction();
                    &.active {color: @brightYellow;}
                    &.left-span{
                        width: 50px;
                    }
                }
                .switch-slider {
                    margin: 0 15px;
                    padding: 0;
                }
            }
            input{
                width: 100px;
                text-align: center;
                padding: 0 10px;
            }
        }
    }
    .keySpan{
        font-size: 13px;
        font-weight: 200;
        color: #5A5D71;
        letter-spacing: 0;
        .val{
            background: rgba(173,202,69,0.2);
            border-radius: 3px;
            margin-left: 10px;
        }
    }

    //.horizontal-menu {
    //    display: flex;
    //    float: left;
    //    width: 60%;
    //    height: 40px;
    //    color: @lightWhite;
    //    span:first-child{
    //        padding-top: 10px;
    //        padding-right: 10px;
    //    }
    //    ul {
    //        list-style-type: none;
    //        margin: 0;
    //        padding: 0;
    //        li {
    //            float: left;
    //            margin-right: 20px;
    //            text-transform: uppercase;
    //            font-size: @fontSizeStandard;
    //            text-align: left;
    //            padding-top: 10px;
    //            height: 100%;
    //            &.active {
    //                color: white;
    //            }
    //            &:hover {
    //                cursor: pointer;
    //                color: @white
    //            }
    //        }
    //    }
    //}

    //.toggle-switch {
    //    position: relative;
    //    height: 35px;
    //    width: 145px;
    //    background: rgba(0, 0, 0, 0.25);
    //    border-radius: 5px;
    //    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    //    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    //
    //    .switch-label {
    //        color: rgba(255, 255, 255, 0.35);
    //        padding: 0;
    //        &.switch-label-iframe{
    //            padding-left: 5px;
    //        }
    //    }
    //    .switch-input:checked + .switch-label {
    //        color: white;
    //    }
    //    .switch-input:checked + .switch-label-on ~ .switch-selection {
    //        left: 69px;
    //        /* Note: left: 50% doesn't transition in WebKit */
    //    }
    //
    //    .switch-selection {
    //        left: 0;
    //        border: solid 2px white;
    //    }
    //}
}

.category-wrap{
    .fa-times:hover{color: red;}
	position: relative;
	margin: auto;
	.fa-plus{
		margin-left: 20px;
		font-size: 17px;
	}
	.add-new-category{
		width: 200px;
		input{
			border-bottom: solid 1px @lightGrey;
			width: 100px;
			height: 20px;
			margin-left: 20px;
			margin-right: 20px;
		}
	}
    .select-category-wrap{
        h3{
            margin: 0;
            padding: 0;
        }
        span{
            text-transform: uppercase;
        }
        .category-select{
            width: 200px;
            height: 40px;
            background: white;
            margin-left: 50px;
        }
    }
    .navigator{
        height: 100%;
		padding: 0;
		margin-top: 15px;
        white-space: nowrap;
        color: @lightWhite;
        overflow-x: scroll;
        overflow-y: hidden;
        padding-bottom: 10px;
        .fa,.addVal{color:@brightYellow}
        li{
            color: @modalTextColor;
        }
    }
}

.valueModal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    #valueModalInput{
        display: block;
        position: relative;
        margin: auto;
        font-size: 20px;
    }
    .butts{
        position: relative;
        width: 150px;
        margin: auto;
        .modal-confirm{
            width: 100%;
            height: 30px;
            margin-top: 15px;
            padding: 0;
        }
    }
}

.label-title{
    color: @modalTextColor;
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
    position: relative;
    &__small{
        font-size: 11px;
        margin-left: 20px;
        text-transform: lowercase;
        color: @modalTextColor;
    }
    .fa-star{
        margin-top: 2px;
        margin-left: 3px;
        font-size: 7px;
        display: inline-block;
        position: absolute;
        color: rgba(255,0,0,0.7);
    }
}

a.favourite-selection{
    position: relative;
    color: @brightYellow;
    .fa-star, .fa-star-o{
        margin-top: 0;
        position: relative;
        margin-right: 10px;
        font-size: 18px;
    }
    .fa-star{color: @brightYellow;}
    .fa-star-o{color: @modalTextColor;}
    :hover{
        color: @brightYellow;
        .fa-star, .fa-star-o{color: @brightYellow;}
    }
}

.set-probes-action{
    margin-top: 20px;
    clear: left;
    width: 100%;
}

.loc-monitoring-wrap{
    clear:both;
    .no-locations {
        margin-left: 20px;
        font-style: italic;
        color: @grayTextColor;
    }
}

.probeName {
    text-overflow: ellipsis;
    overflow: hidden;
    height: 20px;
    width: 275px;
}

.loc-monitoring-list, .alert-option-list {
    &.row{
        flex-direction: row;
    }
    &.column{
        flex-direction: column;
        li {
            width: 300px;
        }
    }
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-left: 0;
    li {
        list-style: none;
        overflow: hidden;
        height: 30px;
        white-space: nowrap;
        user-select: none; /* CSS3 (little to no support) */
        &:hover {
            cursor: pointer;
            label{
                &.inp-check input+span{
                    color: @brightYellow;
                    &:before{
                        border-color: @brightYellow;
                    }
                }
            }
        }
    }
    label {
        display: inline-block;
        padding: 0;
        text-transform: none;
        height: 22px;
        &.inp-check input+span{
            color: #22253D;
            &:before{
                border: 1px solid @modalBorderColor;
            }
        }
    }
    input[type=checkbox] {
        display: none;
        cursor: pointer;
    }
    input[type=checkbox] + span {
        cursor: pointer;
        margin: 0 30px 10px 0;
        min-width: 100px;
    }
    input[type=checkbox] + label::before,
    input[type=checkbox]:checked + label::before {
        font-family: FontAwesome;
        font-size: @fontSizeStandard + 7;
        cursor: pointer;
        vertical-align:middle;
    }
    input[type=checkbox] + label::before {
        content: "\f096";
        padding-right:12px;
    }
    input[type=checkbox]:checked + label::before {
        content: "\f046";
        color: @white;
        padding-right:9px;
    }
    .inp-check {
        input:checked {
            + span {
                &:before {
                    border-color: @brightYellow;
                }
                &:after {
                    color: @brightYellow;
                }
            }
        }
    }
}

.slave-selection-with-tabs{
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    ul:first-child{
        padding: 0;
        margin: 0;
        display: flex;
        list-style: none;
        li{
            border-bottom: 3px solid @modalTextColor;
            padding: 2px 10px;
            width: 100px;
            text-transform: uppercase;
            color: @modalTextColor;
            &.active, &:hover{
                border-color: @brightYellow;
                cursor: pointer;
                color: black;
            }
        }
    }
}

.ReactModal__Body--open{
    overflow: hidden;
}

