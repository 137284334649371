#no-portrait-orient{
    display: none;
}
@media (max-width: 500px) and (orientation:portrait){
    #no-portrait-orient{
        width: 100%;
        height: 100%;
        background: rgba(67,75,80,0.9);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1000;
        display: table;
        text-align: center;
        color: white;
            h1{
                line-height: 40px;
                vertical-align: middle;
                display: table-cell;
        }
    }
}

.chart-container-load {
    display: inline-block;
    width: 94%;
    height: 400px;
    margin: 0 auto;
    text-align: center;
}

body {
    font-family: @font;
    overflow: hidden;
    background: var(--main-content-background);
    button{
        font-family: @font;
        font-size: @fontSizeStandard;
    }
    #mother{
        display: flex;
        width: 100%;
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        .mobile-header{
            display: none;
            height: 50px;
            padding: 10px 20px 0 20px;
            #mobile-menu{
                float: right;
            }
            #mobile-search{
                margin-left: 30px;
                float: left;
            }
            #mobile-settings{
                float: left;
            }
            a{
                color: @lightGrey;
                font-size: 30px;
            }
            @media(max-width: @mobileMax){
                display: block;
            }
        }
        .main-panel, .content-panel{
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            left: 0;
            background: var(--main-content-background);
            &.detail-ip-probes{
                display: flex;
                flex-direction: column;
                .header{margin-top: 50px}
                .ip-list{display: none}
                .panel{
                    background: var(--panel-background);
                    width: 90%;
                    margin: 0 auto;
                    border-radius: 3px;
                    display: flex;
                    min-height: 40px;
                    padding-top: 10px;
                    justify-content: space-around;
                    .title-wrap span{
                        font-size: 17px;
                        color: white;
                        line-height: 34px;
                        &.light{color: var(--panel-text-shade);}
                    }
                    .switches{
                        display: flex;
                        padding-top: 8px;
                        .switch{
                            display: flex;
                            margin-right: 25px;
                            .switch-slider{
                                margin: 1px 7px 0;
                            }
                            span{
                                font-size: @fontSize13;
                                color: var(--panel-text-shade);
                                .linkAction();
                                &.active{color:@brightYellow;}
                            }
                        }
                    }
                    .time-block-wrap{
                        margin: 0;
                    }
                }
            }
            &.side-relative{
                flex-direction: row;
                .side-panel{
                    width: 20%;
                    height: 100%;
                    min-width: 250px;
                    max-width: 400px;
                    padding-top: 60px;
                }
                .content-panel{
                    flex-grow: 1;
                }
            }
        }
        .content-panel{
            padding: 70px 0 10px 0;
            overflow-y: scroll;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
        }
        .generatedContent{
            display: flex;
            flex-direction: column;
            margin-top: 30px;
        }
    }
}
.ip-closer-info{
    font-size: 20px;
    color: @lightGrey;
    text-align: center;
    span{
        line-height: 30px;
    }
}
.invalid-license{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    .wrap{
        display: flex;
        flex-direction: column;
    }
    img{
        height: 40px;
        margin-bottom: 20px;
    }
    .box{
        display: block;
        min-width: 600px;
        text-align: center;
        border: solid 2px red;
        border-radius: 10px;
        font-size: 20px;
        color: rgba(255,255,255,0.7);
        padding: 20px 50px;
        background: rgba(255,0,0,0.15);
        .link{
            color: #25BF72;
        }
        .controls{
            display: flex;
            justify-content: center;
            a{
                padding: 5px 10px;
                border-radius: 5px;
                margin-top: 20px;
                color: #478ad3;
            }
        }
    }
    &.mobile{
        .box{
            min-width: auto;
            width: 90%;
            margin: 0 auto;
            padding: 20px 10px;
        }
    }
}