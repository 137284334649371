.login{
    width: 100%;
    height: 100%;
    display: flex;
    .content{
        display: flex;
        position: relative;
        flex-direction: column;
        width: 90%;
        margin: 30px auto;
        max-width: 1000px;
        .logo{
            margin-bottom: 20px;
            text-align: center;
            .home{
                display: flex;
                justify-content: center;
                #icon-logo{
                    max-width: 230px;
                    width: 100%;
                    height: 80%;
                }
            }
        }
        .sub-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 100%;
            margin: 0 auto;
            max-width: 1000px;
            &__login{

            }
            &__forgot{
                .title{
                    color: white;
                    font-size: 25px;
                    margin-bottom: 20px;
                    width: 100%;
                    text-align: center;
                }
                .desc{
                    color: #969AA0;
                    font-size: 17px;
                    width: 100%;
                    text-align: center;
                    margin: 0 auto;
                    margin-bottom: 20px;
                    width: 75%;
                }
            }
            &--600{
                width: 100%;
                max-width: 600px;
                display: flex;
                flex-direction: column;
            }
            .title{
                color: white;
                font-size: 25px;
                margin-bottom: 20px;
            }
            .input{
                &__span {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    justify-content: flex-start;
                    label {
                        display: block;
                        padding: 5px 0;
                        text-transform: uppercase;
                        color: #A2A5AA;
                        margin-bottom: 10px;
                        &.input--error{
                            color: red;
                        }
                    }
                    input {
                        margin-bottom: 20px;
                        width: 100%;
                        padding: 5px;
                        background: #1D2531;
                        border: none;
                        border-radius: 7px;
                        border: 1px solid #343B45;
                        color: #fff;
                        font-size: 20px;
                        font-weight: 300;
                        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
                        text-align: center;
                        box-sizing: border-box;
                        &.input--error{
                            border: solid red 1px;
                        }
                        &:focus {
                            border: 1px solid #478AD3;
                            outline: none;
                        }
                    }
                }
            }
            .controls{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border: none;
                color: white;
                width: 100%;
                margin: 25px auto 20px;
                .controlLinks {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: fit-content;
                }
                .link{
                    color: #478AD3;
                    padding-top: 15px;
                    &:hover{
                        color: white;
                        cursor: pointer;
                    }
                }
                button{
                    display: flex;
                    padding: 15px 25px;
                    border: none;
                    color: white;
                    border-radius: 7px;
                    background: linear-gradient(to right, #37B779 0%, #2AA385 100%);
                    .sk-fading-circle{
                        width: 20px;
                        height: 20px;
                        position: relative;
                        margin: 0 10px;
                    }
                    &:hover{
                        background: #37B779;
                        cursor: pointer;
                    }
                    &:focus{outline: none;}
                }
                &--center{
                    justify-content: center;
                }
            }
        }
    }
    &__prompt{
        text-align: center;
        color: red;
        margin-bottom: 20px;
        font-size: 18px;
        height: 25px;
        text-transform: uppercase;
    }
    .hot-fix{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 95%;
        top: 0;
        left: 0;
        border: solid #2A53AC;
        border-radius: 10px;
        font-size: 20px;
        color: white;
        background: @mainBackground;
        opacity: 0.8;
        span{
            width: 90%;
            margin: 0 auto;
            text-align: center;
            word-break: break-word;
        }
    }
}
