.aspath{
    display: flex;
    flex-direction: column;
    &__row{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    &__cta{
        margin-top: 5px;
        font-size: 12px;
        cursor: pointer;
        color: var(--panel-text);
        &:hover{cursor: unset;}
        @media(max-width: 600px){display: none}
    }
    .title{
        margin-right: 15px;
        letter-spacing: 1px;
        color: var(--title);
        font-weight: 700;
        line-height: 25px;
        font-size: @fontSize12;
    }
    ul{
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        margin: 0;
        li{
            color: var(--title);
            font-size: 14px;
            font-weight: bold;
            border-radius: 20px;
            height: 25px;
            line-height: 25px;
            text-align: center;
            padding: 0px 10px;
            margin: -1px 5px 10px;
            background: var(--main-content-background);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: auto;
        }
    }
}