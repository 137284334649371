.select2-container{
    width: 300px !important;
    .select2-selection{
        background: @mainBackground;
        color: white;
        &:focus{outline:none};
    }
    .select2-selection__rendered{
        color: #22253D;
    }
    .select2-dropdown{
        background: white;
        color: #22253D;
        border: none;
        border: solid 1px var(--panel-text);
        .select2-search__field{
            margin-top: 10px;
            line-height: 20px;
            border: none;
            color: var(--panel-text);
            &:focus{outline: none}
        }
    }
}

a{
    &:hover{
        cursor: pointer;
        color: white;
    }
    text-decoration:none;
}

.select_style, .select_style.active{
    min-width: 110px;
    background: transparent;
    color: @lightWhiteActive;
    &:hover{
        color: white;
    }
    .top{
        padding-left: 20px;
    }
    .selectcon{
        margin-right: 10px;
    }
    .value_wrapper{
        background: @textColor;
        margin-top: 10px;
        box-shadow: none;
        border: none;
        .values{
            padding: 5px 10px 5px 10px;
            label{
                color: white;
                font-size: @fontSizeStandard;
                font-family: @font;
            }

            &:hover, &.active{
                background: @blueHoverLight;
            }
        }
    }
}

.input-debug-count{
    width: 45px;
    height: 30px;
    -webkit-appearance: none;
}

.disabled{
    color: gray;
    text-decoration: none;
    &:hover{
        cursor: none;
    }
}

.red{
    color: @brightRed
}

.green{
    color: @brightGreen;
}

.inline{
    display: inline-block;
}

.error404{
    margin-top: 2%;
    color: white;
    text-align: center;
    line-height:160%;
    a{
        transition: color 0.6s;
        color:@colorLink;
        &:hover{
            transition: color 0.6s;
            color: lighten(saturate(@colorLink, 25%), 20%);
        }
    }
    h3&.goHome{
        margin-top:6%;
        span {
        letter-spacing: 3px;
        font-size: @fontSizeH2;
        }
    }
    &.wrong-params-msg{
        color: lightgray;
        font-weight: normal;
        font-size: @fontSizeStandard;
    }
}

.hidden{display: none;}
.right{float: right;}
.left{float: left}
.upperCase{text-transform: uppercase;}

.error-msg-form{
    padding:3px 7px;
    border-left:1px dotted @red;
    color:inherit;
    font-style:italic;
    margin-bottom:10px;
    opacity:0.8;
    font-size:@fontSizeSmall;
}
.input-green{
    border-bottom:1px solid @green;
    outline: none;
    box-shadow: 0 10px 17px -13px @green;
}
.input-red{
    border-bottom:1px solid @brightRed;
    outline: none;
    box-shadow: 0 10px 17px -13px @brightRed;
}
.submit-butt-disabled{
    background:darken(@white, 30%) !important;
    color:@white !important;
    &:hover, &:focus{
        background:darken(@white, 30%) !important;
    }
}

.modal .confirm-dialog{
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
    position: absolute;
    background: rgba(255,255,255,0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__in{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2{
            margin: 0;
            padding: 0;
            text-align: center;
        }
        .fa{
            display: block;
            font-size: 125px;
            margin-bottom: 30px;
            &.fa-check-circle{
                color: #00B851;
            }
            &.fa-times-circle{
                color: @red;
            }
        }
    }
    .errPrompt{
        display: block;
        padding: 15px 0;
        color: red;
        font-size: @fontSize13;
    }
    .butts{
        display: flex;
        flex-direction: row;
        width: 80%;
        margin: 0 auto;
        justify-content: center;
        align-items: center;
        button {
            margin: 0 5px

        }

    }

}

.content{
    display: block;
    width: 100%;
    float: left;
}

.margin-20-top{
    margin-top: 20px;
}

.toggle-switch {
    position: relative;
    height: 33px;
    width: 145px;
    background: transparent;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);

    &.accuracy{margin-left: 15px}

    .switch-label {
        position: relative;
        z-index: 2;
        float: left;
        width: 70px;
        line-height: 33px;
        font-size: 15px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.35);
        text-align: center;
        cursor: pointer;
    }

    .switch-label-off {
        padding-left: 2px;
    }

    .switch-label-on {
        padding-right: 2px;
    }

    .switch-input {
        display: none;
    }
    .switch-input:checked + .switch-label {
        color: white;
        -webkit-transition: 0.15s ease-out;
        -moz-transition: 0.15s ease-out;
        -o-transition: 0.15s ease-out;
        transition: 0.15s ease-out;
    }
    .switch-input:checked + .switch-label-on ~ .switch-selection {
        left: 69px;
        /* Note: left: 50% doesn't transition in WebKit */
    }

    .switch-selection {
        display: block;
        position: absolute;
        z-index: 1;
        padding: 0 !important;
        width: 72px;
        height: 30px;
        border-radius: 5px;
        border: solid 2px @panel;
        background: @panel;
        -webkit-transition: left 0.15s ease-out;
        -moz-transition: left 0.15s ease-out;
        -o-transition: left 0.15s ease-out;
        transition: left 0.15s ease-out;
        :hover{
            background: @panel;
        }
    }
}

.space-between{
    display: flex;
    justify-content: space-between;
}

.flex-column{
    display: flex;
    flex-direction: column;
}

.flex-row{
    display: flex;
}

#icon-logo{
    width: 112px;
    height: 22px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.text-center{
    text-align: center;
}
